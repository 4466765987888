<template>
  <div v-if="isShow">
    <h-dialog :title="title" :footerDefault="footerDefault" :isShow="isShow" @okDialog="okDialog"
      @cancelDialog="cancelDialog" :width="800">
      <template #content>
        <div class="flex items-center mt-2.5">
          <div class="w-36 mr-2">Tên coin</div>
          <a-input v-model:value="currentItem.jobName" />
        </div>
        <div class="flex items-center mt-2.5">
          <div class="w-36 mr-2">Đường dẫn tool</div>
          <div class="w-full">
            <h-combobox :data="filePaths" :display="'display'" :keyValue="'value'" @change="onChangeFilePath"
              :value="currentItem.jobScripts.filePath"></h-combobox>
          </div>
        </div>
        <div class="flex items-center mt-2.5">
          <div class="w-36 mr-2">Param thiết lập</div>
          <a-textarea v-model:value="currentItem.jobScripts.param" :rows="6" />
        </div>
      </template>
    </h-dialog>
  </div>
  <div v-else></div>
</template>

<script setup>
import _enum from '@/enum/enum'
import { ModuleJob } from '@/store/module-const'
import { mapGetters, mapActions } from 'vuex'
import commonFunc from '@/common/commonFunc'
</script>

<script>
import { ModuleUser } from '../../../store/module-const'
export default {
  props: {
    isShow: {
      default: false,
      typeof: Boolean
    },
    title: {
      default: '',
      typeof: String
    },
    mode: {
      default: 1,
      typeof: Number
    },
    currentItem: {
      default: {
        jobId: commonFunc.uuidv4(),
        type: 0,
        jobScripts: {
          filePath: '',
          param: '',
        }
      },
      typeof: Object
    }
  },
  computed: {
  },
  data() {
    return {
      footerDefault: {
        cancelText: 'Hủy bỏ',
        okText: 'Lưu',
        cancelStyle: {},
        okStyle: {},
      },
      tabActive: 0,
      filePaths: []
    }
  },
  created() {
    this.initDataStatic();
  },
  methods: {
    ...mapActions(ModuleJob, [
      'updateJob',
      'createJob'
    ]),
    initDataStatic() {
      this.filePaths = [
        { display: 'astrominer', value: '/data/data/com.termux/files/home/minings/dero/astrominer' },
        { display: 'ccminer', value: '/data/data/com.termux/files/home/minings/ccminer/ccminer' },
        { display: 'xmrig', value: '/data/data/com.termux/files/home/minings/xmrig/build/xmrig' },
        { display: 'ccminer53', value: 'tmux/ccminer' },
        { display: 'ccminer55', value: 'tmux/ccminer-55' },
        { display: 'cpuminer', value: 'tmux/cpuminer-opt' },
        { display: 'tnn-miner-036', value: 'tmux/tnn-miner-036' },
        { display: 'rqiner112e', value: 'tmux/rqiner112e' },
        { display: 'rqiner112s', value: 'rqiner112s' },
      ];
      this.currentItem.jobScripts.filePath = this.filePaths[0].value;
    },

    cancelDialog() {
      this.$emit('cancelDialog');
    },
    async okDialog() {
      if (this.mode == _enum.Mode.Edit) {
        await this.updateJob(this.currentItem);
        this.$emit('okDialog', { data: this.currentItem, mode: this.mode });
        return;
      }

      await this.createJob(this.currentItem);
      this.$emit('okDialog', { data: this.currentItem, mode: this.mode });
    },
    onChangeFilePath(value) {
      this.currentItem.jobScripts.filePath = value;
    },
  }
}
</script>
<style lang="scss" scoped>
.grid-devies {
  min-height: 200px;
  max-height: 450px;
  overflow: auto;
}

.text-row {
  display: flex;
  width: 96px;
  padding: 5px 0px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}

.text-task-script-type {
  display: flex;
  padding: 5px 16px;
  align-items: center;
  gap: 10px;
  background: var(--fill-color-blank, #FFF);
  border: 1px solid #DCDFE6;
  color: var(--text-color-regular, #606266);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: -0.01px;
  cursor: pointer;
}

.text-task-script-type-active {
  display: flex;
  padding: 5px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 4px 0px 0px 4px;
  background: var(--color-primary, #409EFF);
  color: var(--color-white, var(--white, #FFF));
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: -0.01px;
  cursor: pointer;
}
</style>